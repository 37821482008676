import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'
import './banner.scss'

const QuoteBanner = ({ phrase, author }) => (
  <div className='section-container quote-banner'>
    <h3>
      <Markdown options={{ html: true }}>
        {`"${phrase}"`}
      </Markdown>
    </h3>
    <Markdown options={{ html: true }}>
      {author}
    </Markdown>
  </div>
)


QuoteBanner.propTypes = {
  phrase: PropTypes.string,
  author: PropTypes.string,
}

export default QuoteBanner
