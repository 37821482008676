import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import Button from '../button'
import './contact.scss'
import { CONTACT_FORM } from '../../config/analytics-events'

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputMessage: '',
  inputLocation: '',
  inputDate: '',
  website: '',
}

class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  formEncode = (obj) => {
    const str = []
    Object.keys(obj).forEach((key) => {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    })
    return str.join('&')
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  submitForm = (e) => {
    const _this = this
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName, inputEmail, inputMessage, inputPhone, inputLocation, inputDate, inputWorkType, website,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })
    fetch('/php/contact-form.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode({
        emailTitle: 'Mensaje desde Pixan website',
        inputName,
        website,
        inputPhone,
        inputEmail,
        inputMessage,
        inputLocation,
        inputDate,
        inputWorkType,
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: CONTACT_FORM.category,
            action: CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        return {
          error: `Ocurrió un error tratando de enviar el formulario.
          Por favor escribínos directamente a hola@pixanfotografia.com.`,
        }
      })
      .then((data) => {
        if (data.error) {
          _this.setState({ contactMessage: { success: false, message: data.error } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageSpanish } })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        this.setState({ sending: false })
      })
  }

  render() {
    const {
      contactMessage,
      inputName,
      inputEmail,
      inputPhone,
      inputMessage,
      inputLocation,
      inputDate,
      website,
      inputWorkType,
      sending,
    } = this.state
    const { title, description } = this.props
    return (
      <div className='contact-container'>
        <h4>{title}</h4>
        <h3>{description}</h3>
        <form
          id='contact_form'
          className='form-container'
          name='contactform'
          data-toggle='validator'
          onSubmit={this.submitForm}
        >
          <div className='form-group'>
            <input
              value={inputName}
              onChange={this.handleChange}
              name='inputName'
              min='2'
              id='inputName'
              placeholder='Nombre Completo*'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputEmail}
              onChange={this.handleChange}
              name='inputEmail'
              id='inputEmail'
              placeholder='Correo*'
              type='email'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputPhone}
              onChange={this.handleChange}
              name='inputPhone'
              id='inputPhone'
              placeholder='Telefono'
              type='text'
            />
          </div>

          <div>
            <select onChange={this.handleChange} id='inputWorkType' name='inputWorkType' value={inputWorkType}>
              <option value='No seleccionado' disabled selected>Tipo de trabajo</option>
              <option value='Boda'>Boda</option>
              <option value='Moda'>Moda</option>
              <option value='Comercial'>Comercial</option>
              <option value='Niños y Familias'>Niños y Familias</option>
              <option value='Embarazadas'>Embarazadas</option>
              <option value='Embarazadas'>Embarazadas</option>
              <option value='Otro'>Otro</option>
            </select>
          </div>

          <div className='form-group'>
            <input
              value={inputDate}
              onChange={this.handleChange}
              name='inputDate'
              id='inputDate'
              placeholder='Fecha estimada dd/mm/aaaa'
            />
          </div>

          <div className='form-group'>
            <input
              value={inputLocation}
              onChange={this.handleChange}
              name='inputLocation'
              id='inputLocation'
              placeholder='Lugar del trabajo'
              type='text'
            />
          </div>
          <div className='textarea-container'>
            <textarea
              value={inputMessage}
              onChange={this.handleChange}
              id='inputMessage'
              name='inputMessage'
              placeholder='Mensaje'
              className='form-control'
              type='text'
              rows='2'
              required
            />
          </div>
          <input type='text' id='website' name='website' value={website} onChange={this.handleChange} />
          <div className='form-group button-container'>
            <Button isButton type='submit' disabled={sending}>
              {sending ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
          <div className='form-group'>
            <div className='g-recaptcha' data-sitekey='6LdPSdMUAAAAAP8BAylz-AwUz9gWYi1Yrhmtj3wJ' />
          </div>
          <div className='contact-message-container'>
            {contactMessage && contactMessage.success
              && <p className='contact-message-success'>{contactMessage.message}</p>
            }
            {contactMessage && !contactMessage.success
              && <p className='contact-message-error'>{contactMessage.message}</p>
            }
          </div>
        </form>
      </div>)
    /* the website field is only for trapping bots */
  }
}

ContactForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
export default ContactForm
