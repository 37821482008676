import React from 'react'
import PropTypes from 'prop-types'

import ContactBanner from '../contactBanner'
import TextSection from '../textSection'
import Menu from '../menu'
import PhotoAlbum from '../photoAlbum'
import './hero.scss'

const HeroSection = ({
  items, metadata, image, title,
}) => {
  const heroStyle = {
    backgroundImage: image && `url(${image.file.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'overlay',
  }

  return (
    <div className='hero-container'>
      {items.map((item) => {
        switch (item.__typename) {
          case 'ContentfulNavBar':
            return <Menu items={item.items} logo={item.logo.file.url} metadata={metadata} />
          case 'ContentfulFotoAlbum':
            return <PhotoAlbum header={item.header} footer={item.footer} photos={item.photos} />
          default:
            return null
        }
      })}
      {image && (
        <div className='hero-image' style={heroStyle}>
          <div className='hero-content'>
            <h4>{title}</h4>
            {items.map((item) => {
              switch (item.__typename) {
                case 'ContentfulTextSection':
                  return <div className='hero-text-content'><TextSection description={item.description} /></div>
                case 'ContentfulContactBanner':
                  return <ContactBanner description={item.description} />
                default:
                  return null
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

HeroSection.propTypes = {
  items: PropTypes.array,
  metadata: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
}

export default HeroSection
