import React from 'react'
import PropTypes from 'prop-types'

import './footer.scss'

const Footer = ({ items, siteMetadata }) => {
  if (!siteMetadata) { return null }
  const {
    telephone,
  } = siteMetadata

  return (
    <div className='footer-wrapper'>
      <div className='footer-container wider-section-container'>
        <div><p>Pixan Productions - All Rights Reserved 2019 ©</p></div>
        <div className='footer-items'>
          <a href={`tel:${telephone}`}>{telephone}</a>
          {items.map((item) => (<a href={item.link}>{item.title}</a>))}
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  items: PropTypes.array,
  siteMetadata: PropTypes.object.isRequired,
}
export default Footer
