import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './banner.scss'

const ContactBanner = ({ description }) => (
  <div className='contact-banner section-container'>
    <h3>
      <Markdown options={{ html: true }}>
        {description.description}
      </Markdown>
    </h3>
  </div>
)

ContactBanner.propTypes = {
  description: PropTypes.string.isRequired,
}

export default ContactBanner
