import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'
import './textSection.scss'

const TextSection = ({
  title, description, link, image, className, imagePosition,
}) => (
  <div className={`${className || 'text-section'} image-${imagePosition}`}>
    <div className='text-container'>
      <h3>{link ? <a href={link}>{title}</a> : title}</h3>
      {description && (
        <p className='text-section-description'>
          <Markdown options={{ html: true }}>
            {description.description}
          </Markdown>
        </p>
      )}
    </div>
    {image && (
      <img key={image.file.fileName} src={image.file.url} alt={image.file.title} className='text-section-image' />
    )}
  </div>
)

TextSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  className: PropTypes.string,
  imagePosition: PropTypes.string,
}

export default TextSection
