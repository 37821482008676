import React from 'react'
import PropTypes from 'prop-types'

import SocialLinks from '../socialLinks'
import './menu.scss'

class Menu extends React.Component {
  constructor() {
    super()
    this.state = { open: false }
  }

  getLogo = () => {
    const { logo } = this.props
    return <a href='/'><img className='menu-logo' src={logo} alt='logo' /></a>
  }

  getMenuItems = (isBurgerMenu) => {
    const { items } = this.props
    return (items.map((item) => (
      <a
        key={item.id}
        onClick={isBurgerMenu && this.toggleMenu}
        href={`${item.link}${item.scrollTo ? `${item.scrollTo}` : ''}`}
        className='menu-link'
      >
        {item.title}
      </a>
    )))
  }

  getSocialMenu = () => {
    const { metadata } = this.props
    const {
      telephone,
      email,
      instagram,
      behance,
    } = metadata
    return (
      <div className='social-items-container'>
        <a href={`tel:${telephone}`} className='menu-link'>
          {telephone}
        </a>
        <a href={`mailto:${email}`} className='menu-link menu-email'>
          {email}
        </a>
        <SocialLinks instagram={instagram} behance={behance} />
      </div>
    )
  }

  toggleMenu = () => {
    const { open } = this.state
    // fix to background scrolling when menu is opened
    if (!this.state.open) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }

    this.setState({ open: !open })
  }

  render() {
    const { open } = this.state
    if (!open) {
      return (
        <div>
          <div className='menu-desktop'>
            <div className='menu-container'>
              {this.getLogo()}
              <div className='desktop-menu-items-container'>{this.getMenuItems()}</div>
              {this.getSocialMenu()}
            </div>
          </div>
          <div className='menu-burger'>
            {this.getLogo()}
            <button className='menu-burger-button' type='button' onClick={this.toggleMenu}>&#9776;</button>
          </div>
        </div>)
    }
    return (
      <div className='menu-overlay'>
        <div className='menu-overlay-header'>
          {this.getLogo()}
          <button href type='button' className='menu-closebtn' onClick={this.toggleMenu}>&times;</button>
        </div>
        <div className='menu-overlay-content'>
          {this.getMenuItems(true)}
        </div>
      </div>)
  }
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.array,
  metadata: PropTypes.object.isRequired,
}

export default Menu
