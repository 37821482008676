import React from 'react'

import ContactForm from './form'

const ContactSection = (props) => (
  <div id='contactUsSection' className='section-container'>
    <ContactForm {...props} />
  </div>
)

export default ContactSection
