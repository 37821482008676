import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './album.scss'
import Carousel from '../carousel'

const PhotoAlbum = ({ photos, header }) => (
  <div>
    {header && (
      <div className='album-header'>
        <h3>
          <Markdown options={{ html: true }}>
            {header.header}
          </Markdown>
        </h3>
      </div>)
    }
    <div className='carousel-container'>
      <Carousel items={photos || []} imgClassName='photo' />
    </div>
  </div>
)

PhotoAlbum.propTypes = {
  header: PropTypes.string,
  photos: PropTypes.array,
}

export default PhotoAlbum
