import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Footer from './footer'
import './index.css'
import './pixanStyles.scss'

class Layout extends React.Component {
  componentDidMount() {
    const { location } = this.props
    if (location) {
      const { hash } = location
      if (hash) {
        this.scrollToSection(hash)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props
    if (location !== nextProps.location) {
      const { hash } = nextProps.location
      this.scrollToSection(hash)
    }
  }

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      window.scroll({
        top: section.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={graphql`
          {
            navBar: allContentfulNavBar {
              edges {
                node {
                  ... NavBarFragment
                }
              }
            }
            siteMetadata: allContentfulSiteMetadata {
              edges {
                node {
                  ... SiteMetadataFragment
                }
              }
            }
          }
          `}
        render={(data) => {
          const { navBar, siteMetadata } = data
          return (
            <div>
              <div>
                {children}
              </div>
              <Footer items={navBar.edges[0].node.items} siteMetadata={siteMetadata.edges[0].node} />
            </div>
          )
        }}
      />)
  }
}

Layout.propTypes = {
  children: PropTypes.func,
  location: PropTypes.object,
}

export default Layout
