import React from 'react'
import PropTypes from 'prop-types'

import './social.scss'

const SocialLinks = ({ instagram, behance }) => (
  <div className='social-icons'>
    <a target='_blank' rel='noopener noreferrer' href={behance} className='menu-link'>
      <img src='/images/behance.svg' alt='My Behance' />
    </a>
    <a target='_blank' rel='noopener noreferrer' href={instagram} className='menu-link'>
      <img src='/images/instagram.svg' alt='My Instagram' />
    </a>
  </div>
)

SocialLinks.propTypes = {
  instagram: PropTypes.string,
  behance: PropTypes.string,
}

export default SocialLinks
