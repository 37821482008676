import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import SocialLinks from '../socialLinks'

import './instagram.scss'

const InstagramSection = ({ description, metadata }) => {
  return (
    <div className='instagram-section'>
      <h5>
        <Markdown options={{ html: true }}>
          {description}
        </Markdown>
      </h5>
      <div className='social-icons-container'>
        <SocialLinks instagram={metadata.instagram} behance={metadata.behance} />
      </div>

      <div>
        <script src='https://cdn.lightwidget.com/widgets/lightwidget.js' />
        <iframe
          title='Instagram feed'
          src='https://cdn.lightwidget.com/widgets/8933cbaaddd050c288c2022425d67f22.html'
          scrolling='no'
          allowTransparency='true'
          className='instagram-widget'
          style={{
            width:'100%',
            border: 0,
            overflow:'hidden',
          }}
        />
      </div>
    </div>
  )
}

InstagramSection.propTypes = {
  description: PropTypes.string,
  metadata: PropTypes.object,
}

export default InstagramSection
