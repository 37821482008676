import React from 'react'
import PropTypes from 'prop-types'

import './button.scss'

const Button = ({
  children, isButton, isLink, className, ...rest
}) => {
  if (isLink) {
    return (
      <button type='button' className={`hype-link ${className}`} {...rest}>
        {children}
      </button>)
  }
  if (isButton) {
    return (
      <button type='button' className={`hype-button ${className}`} {...rest}>
        {children}
      </button>)
  }
  return (
    <a className={`hype-button ${className}`} {...rest}>
      {children}
    </a>)
}

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isButton: PropTypes.bool,
  isLink: PropTypes.bool,
}

export default Button
