import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import TextSection from '../textSection'
import './services.scss'

const ServicesSection = ({ title, description, items }) => (
  <div className='services-container section-container' id='servicios'>
    <h2>{title}</h2>
    {description && (
      <div className='section-description'>
        <Markdown options={{ html: true }}>
          {description.description}
        </Markdown>
      </div>
    )}
    <div className='services-items'>
      {items.map((item) => (
        <TextSection link={item.link} title={item.title} description={item.description} image={item.image} />
      ))}
    </div>

  </div>
)

ServicesSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
}

export default ServicesSection
