import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

import './carousel.scss'

const arrowPropTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className} carousel-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

NextArrow.propTypes = arrowPropTypes

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className} carousel-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

PrevArrow.propTypes = arrowPropTypes

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  centerMode: false,
  slidesToScroll: 1,
  lazyLoad: false,
  variableWidth: true,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  rows: 1,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

class Carousel extends React.Component {
  constructor() {
    super()
    this.state = { firstSlideIndex: 0 }
  }

  beforeChange = (oldIndex, newIndex) => {
    this.setState({ firstSlideIndex: newIndex })
  }

  render() {
    const { items, imgClassName } = this.props
    const { firstSlideIndex } = this.state
    return (
      <Slider {...settings} beforeChange={this.beforeChange}>
        {items.map((item, index) => (
          <div key={item.file.fileName}>
            <img src={item.sizes.src} className={imgClassName} alt={item.file.fileName} />
            <div className={firstSlideIndex === index ? 'photo-title-container' : 'hiden'}>
              {item.description && (
                item.description.split('-').map((descriptionLine) => (
                  <p className='photo-title' key={descriptionLine}>{descriptionLine}</p>
                ))
              )}
            </div>
          </div>
        ))}
      </Slider>
    )
  }
}

Carousel.propTypes = {
  items: PropTypes.array,
  imgClassName: PropTypes.string,
}

export default Carousel
