export const commonTags = (ogTitle, ogDescription, ogKeywords, ogImage) => ([
  { name: 'title', content: ogTitle },
  { property: 'og:title', content: ogTitle },
  { name: 'description', content: ogDescription },
  { property: 'og:url', content: 'https://pixanfotografia.com' },
  { property: 'og:type', content: 'website' },
  { property: 'og:description', content: ogDescription },
  { property: 'og:image', content: ogImage ? `http:${ogImage.file.url}` : '' },
  { property: 'og:image:url', content: ogImage ? `http:${ogImage.file.url}` : '' },
  { property: 'og:image:secure_url', content: ogImage ? `https:${ogImage.file.url}` : '' },
  { property: 'og:image:width', content: ogImage ? ogImage.file.details.image.width : 0 },
  { property: 'og:image:height', content: ogImage ? ogImage.file.details.image.height : 0 },
  { property: 'og:image:height', content: ogImage ? ogImage.file.details.image.height : 0 },
  { property: 'og:image:type', content: ogImage ? ogImage.file.contentType : '' },
  { property: 'og:image:alt', content: ogImage ? ogImage.description : '' },
  { name: 'keywords', content: ogKeywords },
  { name: 'msapplication-TileColor', content: '#f2eee7' },
  { name: 'theme-color', content: '#f2eee7' },
])
